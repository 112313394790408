import React from "react";

const Services = () => {
    return (
        <div className="bg-gray-100 dark:bg-slate-950 text-gray-900 dark:text-white min-h-screen flex flex-col">
            <div className="flex-grow flex flex-col items-center justify-center px-4">
                <div className="text-center max-w-xl w-full">
                    <h1 className="text-5xl font-extrabold mb-8 tracking-tight">Our Services</h1>
                    <div className="space-y-3">
                        <h2 className="text-2xl font-medium">Remodeling</h2>
                        <h2 className="text-2xl font-medium">Custom Home Build</h2>
                        <h2 className="text-2xl font-medium">Additions</h2>
                        <h2 className="text-2xl font-medium">Kitchen Remodeling</h2>
                        <h2 className="text-2xl font-medium">Bathroom Remodeling</h2>
                        <h2 className="text-2xl font-medium">Custom Decks & Porches</h2>
                        <h2 className="text-2xl font-medium">Basement Refinishing</h2>
                        <h2 className="text-2xl font-medium">Roofing & Siding</h2>
                        <h2 className="text-2xl font-medium">Painting - Interior & Exterior</h2>
                    </div>
                </div>
            </div>

            <footer className="shadow-custom text-center py-6 dark:text-white bg-gray-200 dark:bg-slate-900">
                <p className="text-lg">
                    &copy; {new Date().getFullYear()} Eldredge Construction. All rights reserved.
                </p>
            </footer>
        </div>
    );
}

export default Services;
