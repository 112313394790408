import React, { useState } from "react";
import axios from "axios";

const FreeEstimate = () => {
  const [step, setStep] = useState(1);
  const [zipCode, setZipCode] = useState("");
  const [customerInfo, setCustomerInfo] = useState({
    streetAddress: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleZipCodeSubmit = (e) => {
    e.preventDefault();
    if (/^\d{5}(-\d{4})?$/.test(zipCode)) {
      setStep(2);
    } else {
      setStatusMessage("Please enter a valid ZIP code.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Log data before sending to backend
      console.log("Form data on submit:", { zipCode, ...customerInfo });

      const data = { zipCode, ...customerInfo };
      const response = await axios.post("/.netlify/functions/sendEmails", data);

      if (response.status === 200) {
        setStatusMessage("Thank you for your information! Email has been sent, and we will contact you as soon as we can.");
        setIsSubmitted(true);
      } else {
        setStatusMessage("There was an issue with the email. Please try again.");
      }
    } catch (error) {
      console.error("There was an error sending your information:", error);
      setStatusMessage("There was an error sending your information. Please try again.");
    }
  };

  const renderInputField = (label, name, type = "text") => (
    <>
      <label className="block text-left mb-2 font-medium">{label}:</label>
      <input
        type={type}
        name={name}
        value={name === "zipCode" ? zipCode : customerInfo[name]}
        onChange={(e) => {
          name === "zipCode" ? setZipCode(e.target.value) : handleChange(e);
        }}
        className="w-full px-4 py-2 mb-4 border rounded-md bg-gray-50 dark:bg-slate-900 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
      />
    </>
  );

  return (
    <div className="bg-gray-100 dark:bg-slate-950 text-gray-900 dark:text-white min-h-screen flex flex-col items-center justify-center px-4 py-8">
      {step === 1 && (
        <form
          onSubmit={handleZipCodeSubmit}
          className="max-w-lg w-full text-center shadow-lg p-8 rounded-lg bg-white dark:bg-slate-800"
        >
          <h2 className="text-3xl font-bold mb-6 tracking-tight">Get a Free Estimate</h2>
          {statusMessage && (
            <div className="mb-4 text-lg text-red-500">
              {statusMessage}
            </div>
          )}
          {renderInputField("Enter your ZIP code", "zipCode")}
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md font-medium transition duration-150"
          >
            Submit ZIP Code
          </button>
        </form>
      )}

      {step === 2 && (
        <form
          onSubmit={handleSubmit}
          className="max-w-lg w-full text-center shadow-lg p-8 rounded-lg bg-white dark:bg-slate-800"
        >
          <h2 className="text-3xl font-bold mb-6 tracking-tight">
            {isSubmitted ? statusMessage : "Provide Your Information"}
          </h2>
          {renderInputField("Street Address", "streetAddress")}
          {renderInputField("Phone Number", "phoneNumber", "tel")}
          {renderInputField("First Name", "firstName")}
          {renderInputField("Last Name", "lastName")}
          {renderInputField("Email Address", "email", "email")}
          <div>
            <label className="block text-left mb-2 font-medium">Message:</label>
            <textarea
              name="message"
              value={customerInfo.message}
              onChange={handleChange}
              className="w-full px-4 py-2 mb-4 border rounded-md bg-gray-50 dark:bg-slate-900 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md font-medium transition duration-150"
          >
            {isSubmitted ? "Submitted" : "Submit Information"}
          </button>
        </form>
      )}
    </div>
  );
};

export default FreeEstimate;
