import React, { useEffect } from "react";

import { initializeDarkMode } from "./Dark";
import ImageSlider from "./ImageSlider";
import image from "../assets/jens-behrmann-Iy3OdKaszJs-unsplash.jpg";
import greysonImage from '../assets/greyson-joralemon-A1g0oeX29ec-unsplash.jpg';


const Home = () => {
  useEffect(() => {
    initializeDarkMode();
  }, []);

  return (
    <div className="bg-gray-100 dark:bg-neutral-950 dark:text-white min-h-screen py-5">
      <nav className="py-8 mb-4 flex items-center justify-center">
        <header className="text-center">
          <h1 className="text-3xl sm:text-4xl font-extrabold text-sky-800 dark:text-white">
            Eldredge Construction
            <i className="fa-solid fa-person-digging ml-2"></i>
          </h1>
          <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 font-semibold mt-2">
            A family business in Construction Services
          </p>
        </header>
      </nav>

      <div className="container mx-auto py-8 px-4">
        <main>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl mb-4 font-bold dark:text-white">
              Our Services
            </h2>
            <ul className="text-base sm:text-lg dark:text-gray-300 list-disc list-inside pl-4">
              <li className="mb-2">Snow Plowing</li>
              <li className="mb-2">Decks</li>
              <li className="mb-2">Bathrooms</li>
              <li className="mb-2">Kitchens</li>
              <li className="mb-2">Additions</li>
              <li className="mb-2">Houses</li>
              <li className="mb-2">Roofs</li>
              <li className="mb-2">Siding</li>
            </ul>
          </section>

          <section className="mb-8 grid grid-cols-1 lg:grid-cols-2 gap-4">
    <img
        className="w-full h-auto object-cover rounded-lg shadow-lg"
        src={image}
        alt="Construction work"
    />
  <img
    className="w-full h-auto object-cover rounded-lg shadow-lg"
    src={greysonImage}
    alt="Construction site by Greyson Joralemon"
/>

</section>


          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-bold dark:text-white">
              Contact Us
            </h2>
            <div className="flex flex-col text-base sm:text-lg dark:text-gray-300">
              <h3 className="mb-2">Steve: (978)-549-6387</h3>
              <h3>Kyle: (603)-490-6044</h3>
            </div>
          </section>
        </main>

        <div className="slider mb-8">
          <h1 className="text-xl sm:text-2xl font-bold mb-4 dark:text-white">
            Some of Our Work
          </h1>
          <ImageSlider />
        </div>

        <footer className="shadow-custom text-center py-8 bg-gray-100 dark:bg-slate-900">
          <p className="text-gray-900 dark:text-white">
            &copy; {new Date().getFullYear()} Eldredge Construction. All rights
            reserved.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Home;
