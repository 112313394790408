// Dark.js
export function toggleDarkMode() {
    const htmlElement = document.documentElement;
    const isDarkModeEnabled = htmlElement.classList.toggle('dark');
    
    localStorage.setItem('darkMode', isDarkModeEnabled);
    
    const modeButtonIcon = document.getElementById('modeButtonIcon');
    if (isDarkModeEnabled) {
        modeButtonIcon.className = 'fas fa-solid fa-moon';
    } else {
        modeButtonIcon.className = 'fas fa-regular fa-sun';
    }
}

export function initializeDarkMode() {
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode === 'true') {
        document.documentElement.classList.add('dark');
    }
    
    const initialModeButtonIcon = document.getElementById('modeButtonIcon');
    if (document.documentElement.classList.contains('dark')) {
        initialModeButtonIcon.className = 'fas fa-solid fa-moon';
    } else {
        initialModeButtonIcon.className = 'fas fa-regular fa-sun';
    }
}
