import React from 'react';

const About = () => {
  return (
    <div className="bg-gray-100 dark:bg-neutral-950 text-gray-900 dark:text-white min-h-screen flex flex-col items-center justify-center px-4 py-8">
      <div className="max-w-2xl text-center shadow-custom p-8 rounded-lg bg-white dark:bg-neutral-900">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="text-lg inline-flex">
          We have access to the best materials and foundation solutions in the industry to efficiently and effectively solve any problem you might have. All of our technicians are professionally trained, so you can be sure that you’re receiving high standards of professionalism and courtesy at every turn. We give every client individual attention to ensure that we meet all of your goals. If you’re in need of foundation repair work, you’ve come to the right place!
        </p>
      </div>
    </div>
  );
}

export default About;
