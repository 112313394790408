import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import "./index.css";
import Home from './components/Home';
import FreeEstimate from "./components/FreeEstimate";
import About from "./components/About";
import Services from "./components/Services";
import DarkModeToggle from "./components/DarkModeToggle";
import { initializeDarkMode, toggleDarkMode } from "./components/Dark";

function App() {
  const [isDarkMode, setDarkMode] = useState(false);

  // Initialize dark mode on component mount
  useEffect(() => {
    initializeDarkMode();
  }, []);

  // Function to toggle dark mode
  const handleDarkModeToggle = () => {
    toggleDarkMode();
    setDarkMode(!isDarkMode); // Update state to reflect the current mode
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col items-center bg-gray-50 dark:bg-slate-950">
        {/* Dark mode toggle button */}
        <DarkModeToggle toggleDarkMode={handleDarkModeToggle} />

        <div className="w-full">
        <nav className="shadow-custom py-1 mb-4 flex items-center justify-center">
            <ul className="flex justify-center space-x-4 py-4">
              <li>
                <Link
                  className="grey-pink-700 dark:text-gray-300 hover:bg-sky-700 hover:text-white rounded-md px-3 py-2 transition duration-300"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 dark:text-gray-300 hover:bg-sky-700 hover:text-white rounded-md px-3 py-2 transition duration-300"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 dark:text-gray-300 hover:bg-sky-700 hover:text-white rounded-md px-3 py-2 transition duration-300"
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 dark:text-gray-300 hover:bg-sky-700 hover:text-white rounded-md px-3 py-2 transition duration-300"
                  to="/freeestimate"
                >
                  Free Estimate
                </Link>
              </li>
            </ul>
          </nav>

          <Routes>
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/freeestimate" element={<FreeEstimate />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
