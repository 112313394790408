// DarkModeToggle.js
import React from 'react';
import { toggleDarkMode } from './Dark';

const DarkModeToggle = () => {
  return (
    <button className=" flex py-1 mt-3 px-2 bg-black text-white rounded-md dark:bg-white dark:text-black transition-all duration-800" onClick={toggleDarkMode}>
       <i id="modeButtonIcon" className="fas fa-sun fa-regular"></i>
    </button>
  );
};

export default DarkModeToggle;
